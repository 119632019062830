import React from 'react';
import ReactGA from 'react-ga';
import FAQ1 from '../imgs/Apr_2023_FAQ_page_01.svg';
import FAQ2 from '../imgs/Jan_2023_FAQ_page_02.svg';

function initializeAnalytics() {
  ReactGA.initialize('UA-143359903-3', {
    name: 'FAQ Page',
    siteSpeedSampleRate: 100,
  });
  ReactGA.pageview('Intro Page');
}

function FaqPage() {
  initializeAnalytics();
  return (
    <div id="faq-bg" className="hero is-fullheight">
      <div className="container">
        <div className="is-hidden-touch"
          id="viewer"
          style={{
            alignSelf: 'center',
            justifySelf: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <img 
            style={{
              width: '80vw',
              alignSelf: 'center',
            }}
            src={FAQ1} alt="FAQ1"
            />
          <img 
            style={{
              width: '80vw',
              alignSelf: 'center',
            }}
            src={FAQ2} alt="FAQ2"
          />
        </div>
        <div className="is-hidden-lowres-desktop is-hidden-four-k"
          id="viewer"
          style={{
            alignSelf: 'center',
            justifySelf: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <img 
            style={{
              width: '90vw',
              alignSelf: 'center',
            }}
            src={FAQ1} alt="FAQ1"
            />
          <img 
            style={{
              width: '90vw',
              alignSelf: 'center',
            }}
            src={FAQ2} alt="FAQ2"
          />
        </div>
      </div>
    </div>
  );
}

export default FaqPage;
