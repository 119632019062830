/* eslint-disable react/jsx-no-target-blank */
import React, { useRef, useEffect } from 'react';
import { useSpring, animated, useChain } from 'react-spring';
import { Link } from 'react-router-dom';
import logo from '../imgs/project-mercury-logo.svg';
import inquireArrow from '../imgs/inquire-arrow.svg';
import * as easings from 'd3-ease';
import ReactGA from 'react-ga';

function initializeAnalytics() {
  ReactGA.initialize('UA-143359903-3', {
    name: 'Landing Page',
    siteSpeedSampleRate: 100,
  });
  ReactGA.pageview('Intro Page');
}

function LandingPage() {
  initializeAnalytics();

  const leftText = useSpring({
    config: { duration: 1500, easing: easings.easeCubicOut },
    from: { opacity: 1, transform: 'translate(0, -400%)' },
    to: { opacity: 1, transform: 'translate(0, 0)' },
    delay: 200,
  });

  const leftTextMobile = useSpring({
    config: { duration: 1500, easing: easings.easeCubicOut },
    from: { opacity: 1, transform: 'translate(0, 400%)' },
    to: { opacity: 1, transform: 'translate(0, 0)' },
    delay: 200,
  });

  const inquireMoveRef = useRef();
  const inquireMove = useSpring({
    config: { duration: 1500, easing: easings.easeCubicOut },
    from: { transform: 'translate(200%, 0)' },
    to: { transform: 'translate(0, 0)' },
    ref: inquireMoveRef,
  });

  const inquireFadeRef = useRef();
  const inquireFade = useSpring({
    config: {
      duration: 800,
    },
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: inquireFadeRef,
  });

  useChain([inquireMoveRef, inquireFadeRef]);

  useEffect(() => {
    console.log(
      '\n////////////////',
      '\nSITE DESIGN',
      '\n////////////////',
      '\n',
      '\nGarrett DeRossett',
      '\nAlright.studio',
      '\n',
      '\n////////////////',
      '\nSITE DEVELOPMENT',
      '\n////////////////',
      '\n',
      '\nRichard Dominguez',
      '\nricharddominguez.dev',
      '\n',
      '\nChristian Mejia',
      '\nchristianmejia.dev'
    );
  }, []);
  return (
    <div className="hero is-fullheight" id="landing-bg">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-hidden-touch is-hidden-lowres-desktop">
            <div className="column">
              <animated.div style={leftText} id="four-k-blurb">
                <p>
                  PROJECT MERCURY IS A JOINT INITIATIVE FROM DERRIS AND MYTHOLOGY TO SUPPORT UNDERREPRESENTED FOUNDERS WHO HAVE RADICAL IDEAS FOR PRODUCTS OR SERVICES THAT WILL REDEFINE INDUSTRIES. 
                </p>
                <p style={{ marginTop: '32px' }}>
                  WE PROVIDE BRAND AGENCY SERVICES TO EMERGING BUSINESSES FOUNDED, OWNED, AND CHAMPIONED BY BLACK FOUNDERS.
                </p>
                <p style={{ marginTop: '32px' }}>
                  OUR EXPERIENCE BUILDING GREAT BRANDS OVER THE LAST DECADE HAS LED US TO THIS CLEAR CONCLUSION: WE MUST RETHINK THE LANDSCAPE OF INNOVATION TO ENSURE THAT BLACK FOUNDERS ARE POSITIONED AND SUPPORTED TO BUILD, OWN AND LEAD BRANDS THAT MEET THE NEEDS AND ASPIRATIONS OF THE MODERN CONSUMER, AND THE BLACK COMMUNITY AT LARGE.
                </p>
                <p style={{ marginTop: '32px' }}>
                  WE HAVE THE UNIQUE OPPORTUNITY TO USE OUR EXPERTISE TO PROMOTE BLACK OWNERSHIP, LEADERSHIP, AND FACILITATE ECONOMIC EQUITY. THROUGH PROJECT MERCURY, WE AIM TO SUPPORT FOUNDERS WORKING TO BUILD THE NEXT GENERATION OF GREAT BRANDS - A GENERATION THAT IS MORE DIVERSE, INCLUSIVE, AND REPRESENTATIVE OF ALL PEOPLE.
                </p>
                <p>
                  READ MORE DETAILS{' '}
                  <Link to="/FAQ">
                    HERE
                  </Link>
                </p>
              </animated.div>
            </div>

            <div className="column">
              <div className="vertical-logo is-flex-desktop">
                <img
                  className="vertical-logo-img"
                  src={logo}
                  height="65vh"
                  width="auto"
                  alt="projectMERCURY"
                />
              </div>
            </div>

            <div className="column">
              <animated.div style={inquireMove} className="landing-right">
                <animated.div style={inquireFade} className="btn-animation">
                  <Link to="/form">
                    <button id="four-k-btn">
                      <p>SUBMIT</p>
                      <img src={inquireArrow} alt="inquire arrow" />
                    </button>
                  </Link>
                </animated.div>
              </animated.div>
            </div>
          </div>

          <div className="columns is-hidden-touch is-hidden-four-k">
            <div className="column">
              <animated.div style={leftText} id="desktop-blurb">
                <p>
                PROJECT MERCURY IS A JOINT INITIATIVE FROM DERRIS AND MYTHOLOGY TO SUPPORT UNDERREPRESENTED FOUNDERS WHO HAVE RADICAL IDEAS FOR PRODUCTS OR SERVICES THAT WILL REDEFINE INDUSTRIES. 
                </p>
                <p style={{ marginTop: '16px' }}>
                WE PROVIDE BRAND AGENCY SERVICES TO EMERGING BUSINESSES FOUNDED, OWNED, AND CHAMPIONED BY BLACK FOUNDERS.
                </p>
                <p style={{ marginTop: '16px' }}>
                  OUR EXPERIENCE BUILDING GREAT BRANDS OVER THE LAST DECADE HAS LED US TO THIS CLEAR CONCLUSION: WE MUST RETHINK THE LANDSCAPE OF INNOVATION TO ENSURE THAT BLACK FOUNDERS ARE POSITIONED AND SUPPORTED TO BUILD, OWN AND LEAD BRANDS THAT MEET THE NEEDS AND ASPIRATIONS OF THE MODERN CONSUMER, AND THE BLACK COMMUNITY AT LARGE.
                </p>
                <p style={{ marginTop: '16px' }}>
                  WE HAVE THE UNIQUE OPPORTUNITY TO USE OUR EXPERTISE TO PROMOTE BLACK OWNERSHIP, LEADERSHIP, AND FACILITATE ECONOMIC EQUITY. THROUGH PROJECT MERCURY, WE AIM TO SUPPORT FOUNDERS WORKING TO BUILD THE NEXT GENERATION OF GREAT BRANDS - A GENERATION THAT IS MORE DIVERSE, INCLUSIVE, AND REPRESENTATIVE OF ALL PEOPLE.
                </p>
                <p>
                  READ MORE DETAILS{' '}
                  <Link to="/FAQ">
                    HERE
                  </Link>
                </p>
              </animated.div>
            </div>

            <div className="column">
              <div className="vertical-logo is-flex-desktop">
                <img
                  className="vertical-logo-img"
                  src={logo}
                  height="65vh"
                  width="auto"
                  alt="projectMERCURY"
                />
              </div>
            </div>

            <div className="column">
              <animated.div style={inquireMove} className="landing-right">
                <animated.div style={inquireFade} className="btn-animation">
                  <Link to="/form">
                    <button className="inquire-btn">
                      <p>SUBMIT</p>
                      <img src={inquireArrow} alt="inquire arrow" />
                    </button>
                  </Link>
                </animated.div>
              </animated.div>
            </div>
          </div>

          <div className="columns is-mobile is-hidden-desktop">
            <div className="column is-one-quarter">
              <div className="vertical-logo">
                <img
                  src={logo}
                  height="65vh"
                  width="auto"
                  alt="projectMERCURY"
                />
              </div>
            </div>

            <div className="column">
              <animated.div
                style={inquireMove}
                className="landing-right"
                id="mobile-landing">
                <animated.div style={inquireFade} className="btn-animation">
                  <Link to="/form">
                    <button className="inquire-mobile">
                      <p>SUBMIT</p>
                      <img src={inquireArrow} alt="learn more" />
                    </button>
                  </Link>
                </animated.div>

                <animated.div style={leftTextMobile} id="mobile-blurb">
                  <p>
                  PROJECT MERCURY IS A JOINT INITIATIVE FROM DERRIS AND MYTHOLOGY TO SUPPORT UNDERREPRESENTED FOUNDERS WHO HAVE RADICAL IDEAS FOR PRODUCTS OR SERVICES THAT WILL REDEFINE INDUSTRIES.
                  </p>
                  <p style={{ marginTop: '19px' }}>
                  WE PROVIDE BRAND AGENCY SERVICES TO EMERGING BUSINESSES FOUNDED, OWNED, AND CHAMPIONED BY BLACK FOUNDERS.
                  </p>
                  <p style={{ marginTop: '19px' }}>
                  OUR EXPERIENCE BUILDING GREAT BRANDS OVER THE LAST DECADE HAS LED US TO THIS CLEAR CONCLUSION: WE MUST RETHINK THE LANDSCAPE OF INNOVATION TO ENSURE THAT BLACK FOUNDERS ARE POSITIONED AND SUPPORTED TO BUILD, OWN AND LEAD BRANDS THAT MEET THE NEEDS AND ASPIRATIONS OF THE MODERN CONSUMER, AND THE BLACK COMMUNITY AT LARGE.
                </p>
                <p style={{ marginTop: '19px' }}>
                  WE HAVE THE UNIQUE OPPORTUNITY TO USE OUR EXPERTISE TO PROMOTE BLACK OWNERSHIP, LEADERSHIP, AND FACILITATE ECONOMIC EQUITY. THROUGH PROJECT MERCURY, WE AIM TO SUPPORT FOUNDERS WORKING TO BUILD THE NEXT GENERATION OF GREAT BRANDS - A GENERATION THAT IS MORE DIVERSE, INCLUSIVE, AND REPRESENTATIVE OF ALL PEOPLE.
                </p>
                  <p>
                    READ MORE DETAILS{' '}
                    <Link to="/FAQ">
                      HERE
                    </Link>
                  </p>
                </animated.div>
              </animated.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
